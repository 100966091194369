/**/

#main-content-layout {
    overflow-x: hidden;
}

.full-screen-bg {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
}

hr {
    opacity: 0.4;
}

.unselectable-span {
    user-select: none;
}

.hide-on-demand {
    display: none !important;
}

.code-input {
    font-family: Menlo, Monaco, 'SFMono-Regular', Consolas, 'Liberation Mono',
        Courier, monospace !important;
}

.netdata-field {
    margin-bottom: 20px;
}

.logs-output {
    font-family: Menlo, Monaco, 'SFMono-Regular', Consolas, 'Liberation Mono',
        Courier, monospace !important;
    overflow: scroll;
    height: 250px;
    border: 2px solid #cccccc;
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 10px;
    font-size: 90%;
    width: 100%;
}

.logs-output:focus {
    outline: none;
}

.disabled {
    opacity: 0.4;
    cursor: hand;
    pointer-events: none;
}

.one-click-app-card {
    width: 18em;
    margin: 1em;
}

.one-click-app-card img {
    transition: 0.3s ease-in-out;
    opacity: 1;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 12px;
    height: 9em;
    width: 100%;
    object-fit: contain;
}

.one-click-app-card:hover img {
    opacity: 0.8;
}

.pre-line-content {
    white-space: pre-wrap;
}
